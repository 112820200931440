import React, { useEffect, useRef, useState } from "react";
import { useLanguage } from '../contexts/LanguageContext';
import { Link } from "react-router-dom";
import { normalizePhoneNumber } from "../utils/phoneUtils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { setupRecaptcha, sendVerificationCode, verifyCode } from '../utils/phoneAuth';
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { handleVerificationCodeChange, handlePaste } from "../utils/verificationCodeHandling";

const ResetPassword: React.FC = () => {
  const { translations } = useLanguage();
  const [phone, setPhone] = useState('');
  const [normalizedPhone, setNormalizedPhone] = useState<string | null>(null)
  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeVerified, setIsCodeVerified] = useState<boolean>(false);
  const [confirmationResult, setConfirmationResult] = useState<any>(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>(null);
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [step, setStep] = useState(1);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(30);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    if (!recaptchaVerifier) {
      try {
        const auth = getAuth();
        const verifier = setupRecaptcha(auth, 'recaptcha-container');
        setRecaptchaVerifier(verifier);
      } catch (error) {
        console.error('Error initializing reCAPTCHA:', error);
        setError('Error initializing reCAPTCHA');
      }
    }
  }, [recaptchaVerifier]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  const checkPhoneExists = async (phone: string) => {
    try {
      const response = await fetch('/api/user/check-phone', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone, checkType: 'exists' }),
      });
      const data = await response.json();
      return data.exists;
    } catch (error) {
        console.error('Error checking phone:', error);
      return false;
    }
  }

  const sendVerificationCodeLogic = async (normalizedPhone: string) => {
    if (normalizedPhone && recaptchaVerifier) {
      setIsResendDisabled(true);
      try {
        setLoading(true);
        await recaptchaVerifier.verify();

        const result = await sendVerificationCode(normalizedPhone, recaptchaVerifier);
        setConfirmationResult(result);
      } catch (error) {
        console.error("Error during reCAPTCHA verification or sending verification code:", error);
        setError("Error during reCAPTCHA verification or sending verification code");
      } finally {
        setLoading(false);
      }
    }
    let timer = resendTimer;
    const intervalId = setInterval(() => {
      timer -= 1;
      setResendTimer(timer);

      if (timer <= 0) {
        clearInterval(intervalId);
        setIsResendDisabled(false);
        setResendTimer(60);
      }
    }, 1000);
  }

  const nextStep = async () => {
    if (!phone) {
      setError(translations.enterphone);
      return;
    }

    // Check if phone number is valid
    const normalized = normalizePhoneNumber(phone)
    if (normalized) {
      setNormalizedPhone(normalized)
      setError('')
    } else {
      setNormalizedPhone(null)
      setError(translations.errors.invalidphone)
      return;
    }

    // Check if phone number exists
    try {
      const exists = await checkPhoneExists(normalized);
      if (!exists) {
        setError(translations.errors.phonenumbernotfound);
        return;
      }
    } catch (error) {
      setError(translations.errors.genericerror);
      return;
    }

    // If all checks pass, send verification code
    await sendVerificationCodeLogic(normalized);
    setError('');
    setStep(step + 1);
  }

  const checkCode = async () => {
    if (confirmationResult) {
      await verifyCode(confirmationResult.verificationId, verificationCode)
        .then(() => {
          setIsCodeVerified(true);
          setStep(step + 1);
          setError('');
        })
        .catch(() => {
          setError(translations.errors.invalidcode);
        });
    } 
  }

  const prevStep = () => setStep(step - 1);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (newPassword.length < 5) {
      setError(translations.errors.passwordtooshort);
      return;
    }

    if (!isCodeVerified) {
      setError(translations.errors.codenotentered);
      return;
    }

    try {
      const response = await fetch(`/api/user/${normalizedPhone}/newpassword`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ newPassword: newPassword }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.message);
      } else {
        window.location.href = '/login?message=password-updated';
      }
    } catch (error) {
      setError(translations.errors.genericerror);
    }
  }

  return (
    <div className="auth-container">
      <div className="card auth-card">
        <div className="card-header">
          <img src='/mainlogo.png' className='card-header-logo' alt='Reset Password Page Logo'></img>
        </div>
        <form onSubmit={handleSubmit}>  
          <h2>{translations.newpassword}</h2>
          {step === 1 && (
            <div>
              <label>
                {translations.phone}:
                <input
                  type="text"
                  className="auth-input"
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="0401234567"
                  required
                />
              </label>
              {error && <p className="error auth-error">{error}</p>} 
              <button 
                className={`big-button next-button ${loading ? 'disabled' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  nextStep();
                }}
                disabled={loading}
              >
                <div>{loading ? translations.loading : translations.nextstep}</div>
              </button>
              <span className="pswreg"><Link to="/login">{translations.back}</Link></span>
            </div>
          )}
          {step === 2 && (
            <div>
              <label>
                {translations.codesent}: <div className="phonelink auth-phone">{phone}</div>
                <div className="verification-code-inputs">
                  {[...Array(6)].map((_, index) => (
                    <input
                      key={index}
                      type="number"
                      className="verification-code-input"
                      maxLength={1}
                      ref={(el) => (inputRefs.current[index] = el)}
                      onChange={(e) => handleVerificationCodeChange(e, index, verificationCode, setVerificationCode, inputRefs)}
                      onPaste={(e) => handlePaste(e, setVerificationCode, inputRefs)}
                    />
                  ))}
                </div>
              </label>
              { normalizedPhone && (
                <div className="resend-code-container">
                  <div className="resend-code">{translations.didntreceivecode}</div>
                  <span
                    className="resend-code resend-link"
                    onClick={() => {
                      if (!isResendDisabled) {
                        sendVerificationCodeLogic(normalizedPhone);
                      }
                    }}
                  >
                    {isResendDisabled ? `${translations.resendcode} (${resendTimer}s)` : translations.resendcode}
                  </span>
                </div>
              )}
              {error && <p className="error auth-error">{error}</p>} 
              <button 
                className="big-button next-button" 
                onClick={(e) => {
                  e.preventDefault();
                checkCode();
                }}
              >
              {translations.nextstep}
              </button>
              <span className="pswreg" onClick={prevStep}>{translations.back}</span>
            </div>
          )}
          {step === 3 && (
            <div>
              <label>
              {translations.newpassword}: <br/><span className="password-info">{translations.min5chars}</span>
                <div className="input-container-password">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="auth-input"
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder={translations.password}
                    required
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="password-icon"
                    onClick={togglePasswordVisibility}
                  />
                </div>
              </label>
              {error && <p className="error auth-error">{error}</p>} 
              <button className="big-button next-button" type="submit">{translations.changepassword}</button>
              <span className="pswreg" onClick={prevStep}>{translations.back}</span>
            </div>
          )}
        </form>
      </div>
      <div id="recaptcha-container"></div>
    </div>
  );
}

export default ResetPassword;