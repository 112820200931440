import React, { useEffect, useRef, useState } from "react";
import { LoginProps } from "../types/Props";
import { useLanguage } from '../contexts/LanguageContext';
import { Link } from "react-router-dom";
import { normalizePhoneNumber } from "../utils/phoneUtils";
import { useNavigate } from "react-router-dom";
import { useUserLocation } from "../utils/locationUtils";
import { isValidInput, isValidLength } from "../utils/validateInput";
import { useUser } from "../contexts/UserContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { setupRecaptcha, sendVerificationCode, verifyCode } from '../utils/phoneAuth';
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { handleVerificationCodeChange, handlePaste } from "../utils/verificationCodeHandling";

const Register: React.FC<LoginProps> = ({ setIsLoggedIn }) => {
  const { translations } = useLanguage();
  const [step, setStep] = useState(1);
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [normalizedPhone, setNormalizedPhone] = useState<string | null>(null)
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [radius, setRadius] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState<any>(null);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>(null);
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState('');
  const [showRadiusInfo, setShowRadiusInfo] = useState(false);
  const { userLatitude, userLongitude } = useUserLocation();
  const { setUser } = useUser();
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(30);
  const usernamePattern = /^[a-zA-Z0-9_]{3,16}$/;
  const phonePattern = /^\+?[0-9\s\-()]*$/;
  const radiusPattern = /^[0-9]*$/;
  const navigate = useNavigate();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    if (!recaptchaVerifier) {
      try {
        const auth = getAuth();
        const verifier = setupRecaptcha(auth, 'recaptcha-container');
        setRecaptchaVerifier(verifier);
      } catch (error) {
        console.error('Error initializing reCAPTCHA:', error);
        setError('Error initializing reCAPTCHA');
      }
    }
  }, [recaptchaVerifier]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const checkUsernameAvailability = async (username: string) => {
    try {
      const response = await fetch('/api/user/check-username', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_name: username }),
      });
      const data = await response.json();
      return data.available;
    } catch (error) {
      console.error('Error checking username:', error);
      return false;
    }
  }

  const checkPhoneAvailability = async (phone: string) => {
    try {
      const response = await fetch('/api/user/check-phone', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone, checkType: 'availability' }),
      });
      const data = await response.json();
      return data.available;
    } catch (error) {
      console.error('Error checking phone:', error);
      return false;
    }
  }

  const sendVerificationCodeLogic = async (normalizedPhone: string) => {
    if (normalizedPhone && recaptchaVerifier) {
      setIsResendDisabled(true);
      try {
        setLoading(true);
        await recaptchaVerifier.verify();

        const result = await sendVerificationCode(normalizedPhone, recaptchaVerifier);
        setConfirmationResult(result);
      } catch (error) {
        console.error("Error during reCAPTCHA verification or sending verification code:", error);
        setError("Error during reCAPTCHA verification or sending verification code");
      } finally {
        setLoading(false);
      }
    }
    let timer = resendTimer;
    const intervalId = setInterval(() => {
      timer -= 1;
      setResendTimer(timer);

      if (timer <= 0) {
        clearInterval(intervalId);
        setIsResendDisabled(false);
        setResendTimer(60);
      }
    }, 1000);
  }

  const nextStep = async () => {
    // Check if all fields are filled
    if (!username || !phone || !password) {
      setError(translations.errors.fillallfields);
      return;
    }

    // Check if username is required length
    if (!isValidLength(username, 3, 16)) {
      setError(translations.errors.invalidusernamelength);
      return;
    }

    // Check if username contains invalid characters
    if (!isValidInput(username, usernamePattern)) {
      setError(translations.errors.invalidusernamechars);
      return;
    }

    // Check if username is available
    try {
      const available = await checkUsernameAvailability(username);
      if (!available) {
        setError(translations.errors.usernameexists);
        return;
      }
    } catch (error) {
      setError(translations.errors.genericerror);
      return;
    }

    // Check phone number pattern
    if (!isValidInput(phone, phonePattern)) {
      setError(translations.errors.invalidphone);
      return;
    }

    // Check if phone number is valid
    const normalized = normalizePhoneNumber(phone)
    if (normalized) {
      setNormalizedPhone(normalized)
      setError('')
    } else {
      setNormalizedPhone(null)
      setError(translations.errors.invalidphone)
      return;
    }

    // Check if phone number is available
    try {
      const available = await checkPhoneAvailability(normalized);
      if (!available) {
        setError(translations.errors.phoneexists);
        return;
      }
    } catch (error) {
      setError(translations.errors.genericerror);
      return;
    }

    // Check if password is required length
    if (password.length < 5) {
      setError(translations.errors.passwordtooshort);
      return;
    }

    // If all checks pass, proceed to next step
    await sendVerificationCodeLogic(normalized);
    setError('');
    setStep(step + 1);
  }

  const checkCode = async () => {
    if (confirmationResult) {
      await verifyCode(confirmationResult.verificationId, verificationCode)
        .then(() => {
          setIsCodeVerified(true);
          setStep(step + 1);
          setError('');
        })
        .catch(() => {
          setError(translations.errors.invalidcode);
        });
    } 
  }

  const prevStep = () => setStep(step - 1);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (!isValidInput(radius, radiusPattern)){
      setError(translations.errors.invalidradiuschars);
      return;
    }

    if (!isValidLength(radius, 1, 3)){
      setError(translations.errors.invalidradiuslength);
      return;
    }

    if (!isCodeVerified) {
      setError(translations.errors.codenotentered);
      return;
    }

    try {
      const response = await fetch('/api/user/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          user_name: username, 
          phone: normalizedPhone, 
          password, 
          radius,
          latitude: userLatitude, 
          longitude: userLongitude
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.message);
      } else {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(data.user));
        setUser(data.user);
        setIsLoggedIn(true);
        navigate('/');
      }
    } catch (error) {
      setError(translations.errors.genericerror);
    }
  };
  
  return (
    <div className="auth-container">
      <div className="card auth-card">
        <div className="card-header">
          <img src='/mainlogo.png' className='card-header-logo' alt='Register Page Logo'></img>
        </div>
        <form onSubmit={handleSubmit}>   
          <h2>{translations.register}</h2>
          {step === 1 && (
            <div>
              <label>
                {translations.username}:
                <input
                  type="text"
                  className="auth-input"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder={translations.username}
                  required
                />
              </label>
              <label>
                {translations.phone}:
                <input
                  type="text"
                  className="auth-input"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="0401234567"
                  required
                />
              </label>
              <label>
                {translations.password}: <span className="password-info">{translations.min5chars}</span>
                <div className="input-container-password">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="auth-input"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={translations.password}
                    required
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="password-icon"
                    onClick={togglePasswordVisibility}
                  />
                </div>
              </label>
              {error && <p className="error auth-error">{error}</p>} 
              <button 
                className={`big-button next-button ${loading ? 'disabled' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  nextStep();
                }}
                disabled={loading}
              >
                <div>{loading ? translations.loading : translations.nextstep}</div>
              </button>
              <span className="pswreg"><Link to="/login">{translations.back}</Link></span>
            </div>
          )}
          {step === 2 && (
            <div>
              <label>
                {translations.codesent}: <div className="phonelink auth-phone">{phone}</div>
                <div className="verification-code-inputs">
                  {[...Array(6)].map((_, index) => (
                    <input
                      key={index}
                      type="number"
                      className="verification-code-input"
                      maxLength={1}
                      ref={(el) => (inputRefs.current[index] = el)}
                      onChange={(e) => handleVerificationCodeChange(e, index, verificationCode, setVerificationCode, inputRefs)}
                      onPaste={(e) => handlePaste(e, setVerificationCode, inputRefs)}
                    />
                  ))}
                </div>
              </label>
              { normalizedPhone && (
                <div className="resend-code-container">
                  <div className="resend-code">{translations.didntreceivecode}</div>
                  <span
                    className="resend-code resend-link"
                    onClick={() => {
                      if (!isResendDisabled) {
                        sendVerificationCodeLogic(normalizedPhone);
                      }
                    }}
                  >
                    {isResendDisabled ? `${translations.resendcode} (${resendTimer}s)` : translations.resendcode}
                  </span>
                </div>
              )}
              {error && <p className="error auth-error">{error}</p>}
              <button 
                className="big-button next-button" 
                onClick={(e) => {
                  e.preventDefault();
                  checkCode();
                }}
              >
                {translations.nextstep}
              </button>
              <span className="pswreg" onClick={prevStep}>{translations.back}</span>
            </div>
          )}
          {step === 3 && (
            <div>
              {!userLatitude && !userLongitude && (
                <p className="error">{translations.errors.locationfailed}</p>
              )}
              <label>
                <div className="radius-container">
                  {translations.radius}: 
                  <div className="radius-info" onClick={() => setShowRadiusInfo(!showRadiusInfo)}>
                    {translations.whatisradius}
                  </div>
                </div>
                <div className={`radius-info-popup ${showRadiusInfo ? 'show' : ''}`}>
                  <p>{translations.radiusinforegister}</p>
                </div>
                <div className="radius-input-container">
                  <input
                    type="number"
                    className="radius-input"
                    value={radius}
                    onChange={(e) => setRadius(e.target.value)}
                    placeholder="10"
                    required
                  />
                  <span className="radius-unit">{translations.km}</span>
                </div>
              </label>
              {error && <p className="error auth-error">{error}</p>}
              <button className="big-button next-button" type="submit">{translations.register}</button>
              <span className="pswreg" onClick={prevStep}>{translations.back}</span>
            </div>
          )}
        </form>
      </div>
      <div id="recaptcha-container"></div>
    </div>
  );
}

export default Register;