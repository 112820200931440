import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import en from '../locales/en.json';
import fin from '../locales/fin.json';

interface LanguageContextProps {
  language: string;
  setLanguage: (language: string) => void;
  translations: typeof en | typeof fin;
}

const defaultContext: LanguageContextProps = {
  language: 'english',
  setLanguage: () => {},
  translations: en,
};

const LanguageContext = createContext<LanguageContextProps>(defaultContext);

interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 
    (navigator.language.startsWith('fi') ? 'finnish' : 'english'));
  const translations = language === 'finnish' ? fin : en;

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage, translations }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);