import { 
  getAuth, 
  RecaptchaVerifier, 
  signInWithPhoneNumber, 
  ConfirmationResult, 
  signInWithCredential, 
  PhoneAuthProvider 
} from "firebase/auth";

declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier;
    confirmationResult: ConfirmationResult;
  }
}

const setupRecaptcha  = (auth: any, containerId: string) => {
  const recaptchaVerifier = new RecaptchaVerifier(auth, containerId, {
    'size': 'invisible',
    'callback': () => {
      console.log("reCAPTCHA solved!");
    }
  });

  recaptchaVerifier.render();
  return recaptchaVerifier;
};

const sendVerificationCode = (phoneNumber: string, recaptchaVerifier: any): Promise<ConfirmationResult> => {
  const auth = getAuth();
  return signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
};

const verifyCode = (verificationId: string, code: string) => {
  const auth = getAuth();
  const credential = PhoneAuthProvider.credential(verificationId, code);
  return signInWithCredential(auth, credential);
};

export { setupRecaptcha, sendVerificationCode, verifyCode };