import React, { useEffect, useRef, useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { useUser } from '../contexts/UserContext';
import { handlePushNotificationSubscription } from '../utils/pushNotificationUtils';
import Modal from 'react-modal';
import SettingsModal from './SettingsModal';
import NewRequestModal from './NewRequestModal';
import RequestList from './RequestList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useUserLocation } from '../utils/locationUtils';
import apiRequest from '../utils/api';
import usePullToRefresh from '../utils/pullToRefresh'

const Main: React.FC = () => {
  const { translations } = useLanguage();
  const { user } = useUser();
  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
  const [isNewRequestModalOpen, setNewRequestModalOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const {userLatitude, userLongitude } = useUserLocation();
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  // Ask for push-notification permission
  useEffect(() => {
    if (user) {
      handlePushNotificationSubscription();
    }
  }, [user]);

  // Update user's location to database when Main component is mounted
  useEffect(() => {
    if (user && userLatitude && userLongitude) {
      apiRequest(`/api/user/location`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
          latitude: userLatitude, 
          longitude: userLongitude
        }),
      });
    };
  }, [user, userLatitude, userLongitude]);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  }

  const triggerRefresh = () => {
    return new Promise<void>((resolve) => {
      setRefreshTrigger(prev => !prev);
      resolve();
    });
  };

  // Pull to refresh
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { 
    isRefreshing, 
    spinnerSize, 
    handleTouchStart, 
    handleTouchMove, 
    handleTouchEnd 
  } = usePullToRefresh(triggerRefresh, 100, scrollContainerRef);

  return (
    <div className='main'>
      <div className="container">
        <div className="top-bar">
          <img src='/mainlogo_wide.jpg' className='main-page-logo' alt='Main Page Logo'></img>
          <div className="settings" onClick={() => setSettingsModalOpen(true)}><FontAwesomeIcon icon={faBars}/></div>
        </div>
        <div className='card tempInfo'>
          HUOM! Palvelun testausvaihe päättyy 30.11.2024! Tämän jälkeen siirrytään tuotantoympäristöön.
        </div>
        <div 
          className="content"
          ref={scrollContainerRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {isRefreshing && (
            <div 
              className="loading-indicator" 
              style={{ height: `${spinnerSize}px` }}
            >
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          )}
          <div className='requests-header'>
            <h3>{translations.requests}</h3>
            <label className="checkbox-container">
              <input type="checkbox" name="showAll" checked={showAll} onChange={toggleShowAll} />
              <div className='checkmark show-all'></div>
              <p className='show-all-text'>{translations.showall}</p>
            </label>
          </div>
          <RequestList showAll={showAll} refreshTrigger={refreshTrigger}/>
        </div>
        <div className="bottom">
          <button className="big-button new-request" onClick={() => setNewRequestModalOpen(true)}>
           <FontAwesomeIcon icon={faPlus}/> {translations.newrequest}
          </button>
        </div>
      </div>

      <Modal 
        isOpen={isSettingsModalOpen} 
        onRequestClose={() => setSettingsModalOpen(false)} 
        ariaHideApp={false} 
        className="modal"  
      >
        <SettingsModal onClose={() => setSettingsModalOpen(false)} />
      </Modal>
      <Modal 
        isOpen={isNewRequestModalOpen} 
        onRequestClose={() => setNewRequestModalOpen(false)} 
        ariaHideApp={false} 
        className="modal"  
      >
        <NewRequestModal onClose={() => setNewRequestModalOpen(false)} />
      </Modal>
    </div>
  );
}

export default Main;
